.section-heading {
  h3 {
    font-size: 52px;
    line-height: 48px;
    color: #000;
    font-weight: $font-700;
    font-family: $font-montserrat;
    margin-bottom: 20px;
    text-transform: capitalize;
    @media (max-width: 991px) {
      font-size: 40px;
    }
  }
  p {
    max-width: 50%;
    margin: auto;
    color: $gray;
  }

  @media all and (max-width: 768px) {
    padding: 15px;
    h3 {
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 10px;
    }
    p {
      max-width: 100%;
    }
  }
}

.section-head-color {
  h3 {
    font-size: 48px;
    color: $black;
    span {
      color: $red;
    }
  }

  @media all and (max-width: 768px) {
    h3 {
      font-size: 24px;
    }
  }
}
