.hosting-plans{
  padding: 5rem 0;
  position: relative;
  background: #f5f5f5;
  &::before {
    position: absolute;
    content: "";
    background-color: #ebebeb;
    width: 100%;
    height: 298px;
    bottom: 0;
    left: 0;
    z-index: -9;
  }

  .btn-host-order{
    margin: 20px auto 0 auto;
  }

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .hosting-dur-btn{
    .btn{
      min-width: 140px;
      min-height: 42px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;

      &.btn-outline-secondary{
        border-color: #afaac4;
      }
      &.btn-outline-secondary.active:focus{
        box-shadow: none;
      }
      &.btn-outline-secondary:active,
      &.btn-outline-secondary:hover,
      &.btn-outline-secondary.active{
        background-color: #57526f;
        border-color:  #322f44;
      }
     
      @media (max-width: 600px) {
        min-width: 100px;
        padding: 8px;
      }
    }
  }

//  hosting-plans-head
  .hosting-plans-head{
    background-color: #fbfbfb;

    .plan-head-card{
      border-right: 1px solid #ecebeb;
      min-height: 196px;
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      h5{
        font-size: .75rem;
        font-weight: 300;
        margin: 0;
      }
      p{
        font-size: 27px;
        font-weight: 700;
        margin: 0;
          margin-bottom: 0px;
        margin-bottom: 0px;
        color: #57526f;
        text-transform: capitalize;
        margin-bottom: 25px;
      }
      h3{
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        color: #0a4040;
      }
      span{
        font-size: 14px;
        text-decoration: line-through;
      }
    }
  }


//  plan-heading
  .plan-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 15px;
    min-height: 60px;
    background-color: $light-gray;
    h3{
      font-size: 16px;
      line-height: 1;
      font-weight: 500;
      margin: 0;
    }
    span{
      font-size: 14px;
      cursor: pointer;
    }
  }

// Plan Col
  .plan-col{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 15px;
    min-height: 60px;
    p{
      font-size: 15px;
      line-height: 1;
      font-weight: 400;
      margin: 0;
    }
  }
  .accordion-item {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid lighten($dark, 60%);
    &:last-child{
      border-bottom: none;
    }
  }
  .accordion-button:not(.collapsed) {
    color: $white;
    background-color: lighten($dark, 26%);
    box-shadow: none;
  }
  .accordion-button {
    padding: 1.25rem;
    color: $white;
    background-color: lighten($dark, 18%);
    font-size: 16px;
    font-weight: 400;
    &:focus{
      box-shadow: none;
    }
  }
  .accordion-body {
    padding: 0;
  }


  .accordion-button::after {
    width: auto;
    height: auto;
    content: "\e313";
    font-family: "Material Icons";
    background-image: none;
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
  }

}


.div-responsive{
  @media (max-width: 991px) {
    width: 100%;
    overflow: auto;
  }
  .hosting-plans-head{
    @media (max-width: 991px) {
      min-width: 1000px;
    }
  }
  .hosting-plans-toggle{
    @media (max-width: 991px) {
      min-width: 1000px;
    }
  }
}


.hosting-plan-slider-top{
  padding-bottom: 0 !important;
  .hosting-plan{
    @media (max-width:767px) {
      margin-bottom: 50px;
    }
  }
}



// reseller-hosting-plans
.reseller-hosting-plans{
  .hosting-dur-btn{
    .btn{
      min-width: 140px;
      min-height: 42px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;

      &.btn-outline-secondary{
        border-color: #afaac4;
      }
      &.btn-outline-secondary.active:focus{
        box-shadow: none;
      }
      &.btn-outline-secondary:active,
      &.btn-outline-secondary:hover,
      &.btn-outline-secondary.active{
        background-color: #57526f;
        border-color:  #322f44;
      }
     
      @media (max-width: 600px) {
        min-width: 100px;
        padding: 8px;
      }
    }
  }
  .section-heading{
    padding: 60px 0 20px;
  }
  .div-responsive{
    position: relative;
    &:before {
      position: absolute;
      content: "";
      background: url("../../images/small_dot_img (1).png") no-repeat;
      background-size: 100%;
      width: 244px;
      height: 199px;
      top: -55px;
      left: -175px;
    }
    &:after {
      position: absolute;
      content: "";
      background: url("../../images/dots_round_img (2).png") no-repeat;
      background-size: 100%;
      width: 166px;
      height: 140px;
      right: -90px;
      bottom: -80px;
      z-index: -1;
  }
  }
  .hosting-plans-toggle{
    position: relative;
    z-index: 999;
  }
  .accordion-button {
    color: #fff;
    background-color: #5D088E;  
    box-shadow: none;
    font-size: 30px;
    font-weight: 700;
  } 
  .hosting-plans-title-head{
    background-color: #F5F5F5;
    padding: 1rem;
  }
  .accordion-body {
    padding:0;
  }
  .accordion-button::after {
    width: auto;
    height: auto;
    content: "\e313";
    font-family: "Material Icons";
    background-image: none;
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    color: #fff;
  }
  .hosting-plan-title{
    border: none;
    font-size: 30px;
    line-height: 42px;
    font-weight: 800;
    color: #fa2c68;
    text-align: center;
  }
  .hosting-plan-price{
    font-size: 30px;
    line-height: 48px;
    color: #5a3171;
    font-weight: 800;
    // text-transform: uppercase;
    padding: 10px 0;
    text-align: center;
  }
  .table-body{
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    p{
      margin: 0;
    }

    .plan-heading{
      p{
        text-align: left;
        padding-left: 10px;
      }
    }
    .col-3,
    .col{
      border-top: 1px solid #f5f5f5;
      border-right: 1px solid #f5f5f5;
      padding: 12px;
      text-align: center;
    }

    
  }
  .hosting-plan-btn{
    text-align: center;
    padding: 12px;
    border-top: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    .btn-primary{
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      background-color: #fa2c68;
      padding: 12px 20px;
      margin: 5px 0;
      border-radius: 6px;
      border: none;
      text-transform: uppercase;
      letter-spacing: 1px;
      box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
      transition: all ease-in-out 0.3s;

      &:hover{
        background-color: #5D088E;
      }
    }
  }
}

// full-technical
.full-technical{
  padding: 100px 15px;
  background: #41295a;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2F0743, #41295a);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2F0743, #41295a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
  
  color: #fff;
  margin-top: 100px;

  .header{
    text-align: center;
    margin-bottom: 40px;
    h3{
      font-size: 48px;
      line-height: 48px;
      font-weight: 800;
      text-transform: capitalize;
    }
    p{
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      margin-bottom: 35px;
    }
  }
}