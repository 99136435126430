.more-feature-slider .more-feature-slider-item .slider-body {
  min-height: 315px;
}
.more-feature-slider {
  padding: 0 0 80px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  header {
    margin-bottom: 40px;
    h3 {
      color: #464141;
      font-size: 40px;
      text-transform: capitalize;
      @media (max-width: 767px) {
        font-size: 30px;
      }
    }
  }
  .slick-list {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .slick-slide {
    transition: all 0.3s ease-in;
    > div {
      margin: 10px;
    }
  }
  .slick-center {
    transform: scale(1.2);
  }
  .more-feature-slider-item {
    border: 1px solid $border-color;
    box-shadow: 0 2px 3px rgba($black, 0.1%);
    border-radius: 4px;
    background-color: #fff;

    .slider-body {
      padding: 30px;
    }
    h3 {
      color: $black;
      font-size: 18px;
      font-weight: 500;
    }

    p {
      color: $gray;
    }

    .features {
      min-height: 150px;
      ul {
        li {
          display: flex;
          align-items: center;
          color: $black;
          margin-bottom: 8px;
          span {
            &:first-child {
              color: $green;
              margin-right: 5px;
              font-size: 15px;
            }
          }
        }
      }
    }
    footer {
      .btn.btn-info {
        background-color: #efeff0;
        border-color: #fafbff;
        border-radius: 0 0 3px 3px;
        width: 100%;
        text-align: left;
        padding: 15px 20px;
        .from {
          display: block;
          font-size: 13px;
          font-weight: 600;
          text-transform: uppercase;
        }
        .price {
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }
}
