// gr rafi
.header-right > P {
  color: #726ffe;
  font-weight: bold;
  font-size: 20px;
}

a {
  color: #000;
}

.top-level-domain-section {
  color: #1d396b;
  min-height: 100vh;
  background: url(../../images/background/wave.jpg),
    linear-gradient(#ecf4f7, #ecf4f7);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  .domain-title {
    font-weight: bold;
  }
  .domain-text {
    font-weight: 400;
  }
  .domain-card {
    padding: 30px 45px 30px;
    border-radius: 10px;
    box-shadow: 0 0 30px 0 #00000033;
    transition: all 0.5s;
    border-top: 6px solid transparent;
    position: relative;
    &:hover {
      border-top: 6px solid #8e37f8;
      transform: translateY(-10px);
      .domain-card-header .header-left {
        background: #9230f9;
        color: #fff;
      }
      .domain-btn {
        background: linear-gradient(
          90deg,
          #020024 0%,
          #9429f7 0%,
          #6f75ff 100%
        );
        border: 2px solid #9429f7 !important;
        color: white;
      }
    }
    .domain-card-header {
      display: flex;
      margin-bottom: 30px;
      .header-left {
        h2 {
          font-weight: bold;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        border-radius: 100%;
        background: #2742521a;
        margin-right: 10px;
        transition: all 0.5s;
        @media (min-width: 992px) and (max-width: 1199px) {
          width: 110px;
          height: 110px;
          h2 {
            font-size: calc(1rem + 1.2vw) !important;
          }
        }
      }
      .header-right {
        position: absolute;
        font-size: 16px;
        font-weight: 700;
        top: 30px;
        right: 30px;
        color: #1d396b;
        line-height: 20px;
      }
    }
    .domain-card-body {
      .price {
        margin-bottom: 20px;
        font-weight: bold;
      }
      .domain-btn {
        border-radius: 30px;
        border: 2px solid #1d396b;
        font-weight: 600;
        padding: 0.8rem 1.5rem;
        transition: all 0.5s;
      }
    }
  }

  // responsive
  @media all and (min-width: 1440px) {
    min-height: 85vh;
  }
}
