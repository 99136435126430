.common-text-desc{
  h3{
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 20px;
    color: $black;
    text-align: center;
    text-transform: capitalize;
    font-family: "Barlow", sans-serif;

    @media (max-width: 768px) {
      font-size: 24px;
    }

    span{
      color: $red;
    }

    p{
      margin-bottom: 15px;
    }
  }
}
