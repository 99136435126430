.navbar {
    &.navbar-light{
        background-color: $white;
        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
        .navbar-nav {
            .nav-item{
                @media (min-width: 991px) {
                    margin-left: 30px;
                }
                .nav-link {
                    color: rgba(0, 0, 0, 1);
                    font-family: $font-ubuntu;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    margin-bottom: -12px;
                    &:hover{
                        color: $red;
                    }
                }
            }
        }
    }
    .dropdown-menu {
        padding: 0;
        border-radius: 0 0 5px 5px;
        border-width: 3px 0 0 0;
        border-style: solid;
        border-color: $yellow;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        margin-top: 12px;
        .dropdown-item {
            font-size: 15px;
            padding: 0.8rem 1rem;
            color: rgba(0, 0, 0, 0.8);
            &:hover{
                background-color: $red;
                color: $white;
            }
        }
    }
    &.navbar-light {
        .navbar-toggler {
            border: none;
            &:focus{
                box-shadow: none;
            }
        }
        .navbar-toggler-icon {
            background-image: url("../../images/bar.svg");
        }
    }

}
.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}
