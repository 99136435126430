.hosting-awesome-features-area {
  padding: 4rem 0px;
  background: url(../../images/background/subtle_white_feathers.png) repeat;
  background-position: center;
  margin-top: 120px;
  .title {
    color: #000000;
    margin-bottom: 30px;
    .colored-text {
      color: #e74c3c;
      
    }
  }
  .details {
    font-size: 14px;
    text-align: justify;
    line-height: 30px;
    font-weight: 400;
  }
  .feature-card {
    margin-bottom: 30px;
    .feature-icon-box {
      width: 80px;
      height: 80px;
      border-radius: 10px;
      display: table;
      text-align: center;
      margin: 0 auto;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      figure {
        display: table-cell;
        vertical-align: middle;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    .feature-icon-bg-red {
      background-color: #e74c3c;
    }
    .feature-icon-bg-yellow {
      background-color: #ffb401;
    }
    .feature-text {
      font-size: 14px;
      font-weight: 400;
      padding-top: 15px;
    }
  }
}
