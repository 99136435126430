.hosting-features{
    padding: 100px 0;
  padding-top: 0;
    @media (max-width: 767px) {
        padding: 30px 0;
    }

    .hosting-feature{
        h3{
            font-size: 30px;
            color: $black;
            max-width: 50%;
            @media (max-width: 767px) {
                font-size: 20px;
                max-width: 90%;
            }
        }
        p{
            font-size: 18px;
            @media (max-width: 767px) {
                font-size: 15px;
                max-width: 100%;
            }

        }
    }
    .hosting-features-img{
        img{
            width: 50%;
            margin: auto;
        }
        @media (max-width: 767px) {
            margin-bottom: 30px;
            img{
                width: 70%;
                margin: auto;
            }
        }
    }
}


.hosting-features-style-2{
    padding: 100px 0;
    @media (max-width: 767px) {
        padding: 30px 0;
    }
    .hosting-feature{
        h3{

            @media (max-width: 767px) {
                font-size: 24px;
                max-width: 90%;
            }
        }
        p{

            @media (max-width: 767px) {
                font-size: 15px;
                max-width: 100%;
            }

        }
    }
    .hosting-features-img{
        img{
            width: 80%;
            margin: auto;
        }
        @media (max-width: 767px) {
            margin-bottom: 30px;
            img{
                width: 70%;
                margin: auto;
            }
        }
    }
}



//hosting-features-fluid
.hosting-features-fluid{
    .content{
        h3{
            font-size: 28px;
            font-weight: 500;
            color: $black;
            @media (max-width: 768px) {
                font-size: 20px;
            }
        }
        h5{
            font-size: 18px;
            font-weight: 500;
            color: $black;
            margin-bottom: 20px;

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }
    }
    .img{
        img{
            width: 100%;
            display: block;
            margin: 0 auto;
        }
        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
}
