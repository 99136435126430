*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
   background-color: $white;
   color: $gray;
   font-size: 15px;
   line-height: 1.5;
   font-weight: $font-400;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

ul,ol{
    margin: 0;
    padding: 0;
    list-style-type:none
}

a{
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
}

.btn{
  &:focus{
    box-shadow: none;
    outline: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-family: $font-ubuntu;
}

button,input{
    &:hover,
    &:focus{
        outline: none;
        box-shadow: none;
    }
}

#root{
  width: 100%;
  height: 100vh;
}

br{
  @media (max-width:768px) {
    display: none;
  }
}