.list-features{
  padding: 100px 0;
  background: #0f0c29;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
  
  @media (max-width: 767px) {
    padding: 30px 0;
  }

  h3{
    color: #fff;
    font-size: 40px;
  }
  span{
    color:#fff;
  }
  ul{
    li{
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 5px;

      span{
        &:first-child{
          color: #258CDF;
          margin-right: 15px;
          display: block;
        }
      }
    }
  }
}
