.our-team{
    padding: 100px 0;
  @media (max-width: 768px) {
    padding: 40px 0;
  }

  .team-card{
    text-align: center;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in;

    &:hover{
      background-color: #EDF2F5;
    }

    h3{
      font-size: 22px;
      font-weight: 500;
      color: $black;
      margin: 15px 0 10px 0;
    }
    p{
      font-size: 14px;
    }
  }
}

.team-commit{
  padding-left: 50px;
  margin-top: 50px;
  h3{
    font-size: 24px;
    font-weight: 600;
    color: lighten($black, 25%);
    margin-bottom: 20px;
  }
  ul{
    li{
      display: flex;
      position: relative;
      font-size: 18px;
      margin: 6px 0;
      padding-left: 30px;
      &:after{
        font-family: "Material Icons";
        content: '\e837';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        color: $green;
      }
    }
  }
}
