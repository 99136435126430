.header-small{
  background-image: url("../../images/bgb.jpg");
  background-size: cover;
  background-position: center;
  min-height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3{
    font-size: 48px;
    font-weight: $font-700;
    color: $white;
    margin-top: 29px;
  }
  p{
    padding: .3em 0.8em;
    // background: linear-gradient(to right, #F9D423, #F9D423);
    color: #fff;
    font-weight: 600;
    font-size:18px;
  }

  @media (max-width: 767px) {
    h3{
      font-size: 24px;
    }

  }

}
