
// ADDED BY GR RAFI::

.small-font {
	font-size: 21px;
	font-weight: normal;
}



// END



.hero-home{
  background-size: cover;
  max-height: 550px;
  .center_hero{
    min-height: 550px;
    color: $white;
    .content{
      h3{
        font-family: $font-ubuntu;
        font-size: 54px;
        line-height: 1.1;
        font-weight: $font-700;
        
        @media (max-width: 767px) {
          font-size: 30px;
        }
        @media (min-width:991px) and (max-width:1200px) {
          font-size: 50px;
        }
      }
      p{
        font-size: 27px;
        font-family: $font-montserrat;
        font-weight: 500;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        strong {
          font-size: 29px;
          color: #faa51e;
          font-weight: 900;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
      }
    }

    .hero-offer{
      margin-top: 40px;
      width: 100%;
      @media (max-width: 767px) {
        margin-top: 20px;
      }
      .offer-list{
        background-color: rgba(255, 255, 255, 0.2);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 30px;
        text-align: left;
        @media (max-width: 767px) {
          padding: 15px;
        }
        p{
          width: 50%;
          margin-bottom: 0;
          padding: 6px 0 6px 20px;
          font-size: 16px;
          font-weight: $font-500;
          position: relative;
          @media (max-width: 767px) {
            width: 100%;
            font-size: 14px;
            padding-left: 40px;
          }
          &:after{
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 20px;
            border: 2px solid #fff;
            position: absolute;
            left: 0;
            top: 15px;
            @media (max-width: 767px) {
              left: 20px;
              top: 12px;
            }
          }
        }
      }
    }
    .offer-footer{
      border-radius: 0 0 4px 4px;
      overflow: hidden;
      background-color: #222328;
      .offer{
        width: 60%;
        padding:12px 30px;
        text-align: left;
        max-height: 70px;
        min-height: 70px;
        @media (max-width: 767px) {
          width: 45%;
          padding: 10px
        }
        h4{
          font-size: 36px;
          font-weight: $font-700;
          line-height: 1.2;
          margin-bottom: 0;
          font-family: $font-ubuntu;
          small{
            font-size: 22px;
            font-weight: $font-500;
          }
          @media (max-width: 767px) {
            font-size: 20px;
            small{
              font-size: 16px;
            }
            del{
              display: block;
            }
          }
          @media (max-width: 375px) {
            font-size: 22px;
            small{
              font-size: 16px;
            }
          }
        
        }
      }
      .offer-btn{
        width: 40%;
        @media (max-width: 767px) {
          width: 55%;
        }
        .btn-primary{
          clip-path: polygon(9% 0%, 100% 0%, 100% 100%, 0% 100%);
          background-color: $red;
          border-color: $red;
          border-radius: 0;
          width: 100%;
          padding: 20px;
          font-size: 18px;
          font-weight: $font-700;
          max-height: 70px;
          min-height: 70px;
          font-family: $font-montserrat;
          &:hover{
            background-color: $white;
            border-color: $white;
            color: $red;
          }
          &:focus{
            box-shadow: none;
            outline: none;
          }

          @media (max-width: 767px) {
            padding: 15px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
