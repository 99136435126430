.lvh-btn.shared_hosting_reg_btn.btn.btn-primary {
	border: 1px solid #c8923b;
}
.lvh-btn.shared_hosting_reg_btn.btn.btn-primary:hover {
	background:#d74141;
  color:#fff;
}
.lvh-btn.shared_hosting_btn.btn.btn-primary {
	border: 1px solid #5964d7;
}

.lvh-btn.shared_hosting_btn.btn.btn-primary:hover {
	background: #d74141;
  color: #fff;
}


.hosting-plan-box{
  padding: 120px 0;

  @media (max-width: 767px) {
    padding: 50px 0;
  }
  .shared_hosting_btn{
    background: #3947d7;
    color: #fff;
    border-color: 1px solid #1122ce !important;   
    
  }
  .shared_hosting_reg_btn{
    background: #dd9b33;
    color: #fff;
  }

  .hosting-plan-center{
    position: relative;
    @media (max-width: 767px) {
      border-radius: 6px;
      overflow: hidden;
    }
    &:after,
    &:before{
      content: '';
      display: block;
      position: absolute;
      @media (max-width: 767px) {
        display: none;
      }
    }
    &:after{
      background-image: url("../../images/dot_img.png");
      width: 427px;
      height: 199px;
      top: -55px;
      left: -158px;
      z-index: -1;
    }
    &:before{
      background-image: url("../../images/dots_round_img.png");
      width: 166px;
      height: 140px;
      right: -90px;
      bottom: -80px;
      z-index: -1;
    }




    //hosting-plan-card

// added by gr rafi START
    .yellow_color{

      background: #faa51e;
      margin-right: 6px;
      padding: 2px;
    }

    .bg-cus1{
      background: #690f9b;
    }
    
    .heading.headingx1 {
      margin-top: 0px !important;
    }


// added by gr rafi END



    .hosting-plan-card{
      color: $white;
      padding: 40px;
      @media (max-width: 767px) {
        padding:50px 15px;
      }
      .heading{
        font-size: 48px;
        font-weight: $font-900;
        font-family: $font-ubuntu;
        text-transform: uppercase;
        max-width: 70%;
        margin-bottom: 20px;
        @media (min-width:991px) and (max-width:1200px) {
          font-size: 40px;
        }
        @media (max-width: 767px) {
          font-size: 24px;
          max-width: 100%;
          margin-top:171px;
        }
        &.heading-half{
          max-width: 40%;
          margin-bottom: 0;
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
      .price{
        font-size: 24px;
        font-weight: $font-400;
        margin-bottom: 12px;
        span{
          font-size: 16px;
        }
        @media (min-width:991px) and (max-width:1200px) {
          font-size: 20px;
        }
        @media (max-width: 767px) {
          font-size: 18px;
          span{
            font-size: 14px;
          }
        }
      }


      .hint-text{
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 10px;
        span{
          margin-right: 8px;
        }
      }

      &.card-big{
        min-height: 398px;
      }
      &.card-small{
        min-height: 361px;
        @media (max-width: 767px) {
          min-height: 180px;
        }
      }
      &.bg-blue{
        border-bottom-right-radius: 10px;
         @media (max-width: 767px) {
          border-radius: 0;
        }
      }
      &.bg-red-dark{
        border-bottom-left-radius: 10px;
         @media (max-width: 767px) {
          border-radius: 0;
        }
      }

      &.website-bg,
      &.domain-bg{
        position: relative;
        overflow: hidden;
        @media (max-width: 767px) {
          border-top-right-radius: 0 !important;
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
        }
      }
      &.website-bg{
        border-top-left-radius: 10px;
        &:after{
          background: url("../../images/2.svg") no-repeat;  
          width: 310px;
          height: 297px;
          right: 14px;
          bottom: -53px;
          @media (max-width: 767px) {
            width: 215px;
            height: 215px;
            bottom: 140px;
          }
          img{width: 200px;}
          @media (min-width:991px) and (max-width:1200px) {
            width: 235px;
            height: 200px;
            bottom: 0;
          }
        }
      }
      &.domain-bg{
        border-top-right-radius: 10px;

        &:after{
          background-image: url("../../images/4.svg");
          background-repeat: no-repeat;
          width: 343px;
          height: 249px;
          right: 0;
          bottom: 0;
          @media (min-width:991px) and (max-width:1200px) {
            width: 250px;
            height: 200px;
            right: 0;
            bottom: 0;
          }
          @media (max-width: 767px) {
            width: 230px;
            height: 230px;
            right: 10px;
            bottom: 123px;
          }
        }
      }
    }
  }
}
