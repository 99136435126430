.what-section{
  padding: 60px 0;
  background-image: url("../../../assets/images/bg9.png");
  @media (max-width: 768px) {
    padding: 30px 0;
    
  }

  h3{
    font-size: 36px;
    margin-bottom: 20px;
    font-family: "Barlow", sans-serif;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .btn{
    margin-top: 40px;
    min-width: 150px;
    min-height: 42px;
  }
}
