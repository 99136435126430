.vps-level-hosting{
  padding: 100px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }


  .image{
    img{
      width: 70%;
      margin: 0 auto 30px auto;
      display: block;
    }
  }
}
