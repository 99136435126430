// Fonts
$font-montserrat: 'Barlow', sans-serif;
$font-ubuntu: 'Ubuntu', sans-serif;

// Font Weight
$font-100: 100;
$font-200: 200;
$font-300: 300;
$font-400: 400;
$font-500: 500;
$font-600: 600;
$font-700: 700;
$font-800: 800;
$font-900: 900;


// Brand Color
$red: #EE4343;
$yellow: #ffb401;
$black: #181818;
$gray: #777;
$light-gray: #F1F1F1;
$red-light: #FFF8F6;
$blue-dark: #02345C;
$white-dark: #b3c2ce;

$light-green: #D9F2E2;


//

// Colors
$color-primary:$red;
$color-sndry: $yellow;



//Bg colro
$purple:#4a58e8;
$yellow: #FAA51E;
$red-dark: #D74141;
$green: #32D05E;
$green-dark:#25B36C;
$blue: #2C5CFA;




// customize by gr rafi:
$cus_color1: #000346;
