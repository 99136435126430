.why-choose-levohost-area {
  padding: 4rem 0px;
  .why-choose-area-header {
    .title {
      color: #000000;
      margin-bottom: 20px;
      .colored-text {
        color: #e74c3c;
      }
    }
    .details {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .features-card-area {
    .card {
      transition-duration: 0.5s;
      border: 2px dashed #d7d7d7;
      border-radius: 4px;
      padding: 50px 10px;
      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px 0 #00000033;
        border: 2px solid #fff;
        .card-title {
          color: #e74c3c;
        }
      }
      .icon-box {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .card-title {
        font-size: 18px;
        color: #000;
        transition-duration: 0.5s;
      }
      .feature-text {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
