.dedicate-plan-price{
    position: relative;
    &::before{
        position: absolute;
        content: "";
        background-image: url('../../images/small_dot_img.png');
        background-size: 100%;
        width: 244px;
        height: 199px;
        top: -55px;
        left: -175px;
        z-index: -1;
    }
    &::after{
        position: absolute;
        content: "";
        background-image: url('../../images/dots_round_img (1).png');
        background-size: 100%;
        width: 166px;
        height: 140px;
        right: -90px;
        bottom: -80px;
        z-index: -1;
    }
    .plans-item{
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 0 0 2px #eee inset;
        margin-bottom: 15px;
        background-color: #fff;

        .plans-item-top{
            background-color: #5d088e;
            display: flex;
            align-items: center;
            padding: 15px 30px;
            h3{
                font-size: 30px;
                line-height: 48px;
                font-weight: 800;
                color: #fff;
                text-transform: uppercase;
                margin-right: auto;
                margin-bottom: 0;
            }
            h4{
                font-size: 30px;
                line-height: 48px;
                font-weight: 800;
                color: #faa51e;
                text-transform: uppercase;
                margin: 0 40px;
            }
            .pink-btn{
                font-size: 14px;
                line-height: 14px;
                transition: all ease-in-out 0.3s;
                font-weight: 400;
                color: #fff;
                text-transform: uppercase;
                background-color: #fa2c68;
                border-radius: 6px;
                padding: 12px 35px;
                border: none;
            }
            @media (max-width: 991px) {
                h3{
                    font-size: 20px;
                    line-height: 20px;
                }
                h4 {
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            @media (max-width: 767px) {
                flex-direction: column;
                h3{
                    margin-right: 0;
                    margin-bottom: 10px;
                }
                h4{
                    margin-bottom: 10px;
                }
            }
        }
        .plans-item-bottom{
            display: flex;
            align-items: center;
            padding: 20px 30px;
            overflow-x: auto;
            .item-icon{
                display: flex;
                align-items: center;
                margin-right: 30px;
                .icon{
                    display: inline-block;
                    margin-right: 10px;
                    height: 30px;
                    img{
                        width: 113%;
                        height: 110%;
                        object-fit: cover;
                    }
                }
                .sub-title{
                    font-size: 13px;
                    font-weight: 700;
                    opacity: 0.8;
                }
                &.item-icon-first{
                    margin-right: 5px;
                }
                &:last-child{
                    margin-right: 0;
                }
                svg{
                    margin-right: 15px;
                    path{
                        fill: #5D088E;
                    }
                }
                p{
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 400;
                    vertical-align: middle;
                    text-transform: uppercase;
                    margin: 0;
                }
            }

        }
    }
}

.feature_row02 {
    padding: 40px 0;
    .col-xl-5.col-lg-5{
        position: relative;
    }
    @media (min-width: 1200px) {
        .feature_img_box {
            margin-left: 20px !important;
        }
        .feature_content_box {
            padding-left: 60px !important;
        }
    }
}
.feature_row01{
    .feature_img_box{
        margin-left: 60px;
        @media (max-width: 991px) {
            margin-left: 0px;
        }
    }
}
.feature_row03 {
    @media (min-width: 1200px) {
        .feature_img_box {
            margin-left: 20px !important;
        }
        .feature_content_box {
            padding-left: 60px !important;
        }
    }
}
.feature_row04{
    margin-top: 20px;
    .feature_img_box{
        h3{
            margin-top: 0;
        }
    }
    .feature_content_box {
        @media (min-width: 992px) {
            padding-left: 85px;
        }
    }
}
.circle_shape {
    position: relative;
}
.circle_shape:before {
    position: absolute;
    content: "";
    background-image: url('../../images/elipse_shape.png');
    width: 400px;
    height: 720px;
    top: 25px;
    left: 270px;
    z-index: -5;
    @media (max-width: 1199px) {
        display: none;
    }
}
.feature_img_box {
    background: #3e7ccc;
    width: 162px;
    height: 162px;
    border-radius: 100%;
    border: 20px solid #f0f5fa;
    display: table;
    transition: .5s ease-in-out;
    position: relative;
    z-index: 9;
}
.feature_img_box figure {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.feature_img_box figure img {
    width: 64px;
}
.features_absoulte_img {
    position: absolute;
    width: 350px;
    height: 350px;
    border-radius: 100%;
    overflow: hidden;
    top: 10px;
}
.feature_content_box {
    text-align: left;
}
.feature_content_box h3 {
    font-size: 24px;
    line-height: 24px;
    color: #000;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 20px;
}
.margin-x1{
    width: 270px;
}
.margin-x2{
    width: 250px;
}
.margin-x3{
    width: 200px;
}
.margin-x4{
    width: 235px;
}
.margin-x5{
    width: 200px;
}