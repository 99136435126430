//edited by gr rafi:
.green_clr{
  color:#AA692F;
}
.dis_bg{
  color: #ff8e00;
  font-weight: 700;
  padding: 3px;
  font-size: 20px
}


//..................

//Buttons
.lvh-btn{
  text-transform: uppercase;
  display: inline-block;
  padding: 13px 35px;
  border-radius: 4px;
  transition: all ease-in-out 0.5s;
  font-size: 14px;
  font-family: $font-ubuntu;
  letter-spacing: 0.3px;

  @media (max-width: 767px) {
    padding: 8px 25px;
    font-size: 12px;
  }

//  Color Variation
  &.btn-primary{
    background-color: $red;
    border-color: $red;

    &:hover{
      background-color: transparent;
      color: $red;
    }
  }
  &.btn-warning{
    background-color: $yellow;
    border-color: $yellow;
    color: $white;

    &:hover{
      background-color: transparent;
      color: $yellow;
    }
  }

  &.btn-outline-light{
    border-color: $white;
  }
  &.btn-outline-primary{
    border-color: $red;
    color: $red;
    &:hover{
      background-color: $red;
      color: $white;
    }
  }
}

//  Custom Button
.btn{
  &.btn-order{
    background-color: #55524E;
    border-color: #53514E;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 15px;
    border-radius: 0;
    box-shadow: 0 5px 20px rgba(24, 24, 24, 0.32);
    letter-spacing: 1px;
    font-family: "Barlow", sans-serif;
    width: 100%;
    margin: 0 auto;
  }

  &.btn-link{
    font-size: 15px;
    font-weight: 400;
    color: #7e7f84;
    display: flex;
    align-items: center;
    justify-content:start;
    text-decoration: none;

    &:hover{
      color: $red;
    }

    span{
      margin-right: 10px;
    }
  }

  &.btn-success{
    background-color: $green;
    border-color: $green;
    color: $white;
    border-radius: 3px;
    &:hover{
      background-color: darken($green, 5%);
      border-color: darken($green, 5%);
    }
  }
  &.btn-danger{
    background-color: $red;
    border-color: $red;
    color: $white;
    border-radius: 3px;
    &:hover{
      background-color: darken($red, 20%);
      border-color: darken($red, 20%);
    }
  }

  &.btn-host-order{
    max-width: 160px;
    min-height: 42px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-color: $red;
    color: $red;
    &:hover{
      background-color: $red;
      color: $white;
    }
  }

}


//Custom Buttons
.btn-border-1{
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 40px;
    font-size: 14px;
    &.btn-success{
      box-shadow: 0 3px 0 darken($green, 5%);
    }
    &.btn-danger{
      box-shadow: 0 3px 0 darken($red, 20%);
    }

}


//Avatar
.avatar{
  width: 72px;
  height: 72px;
  min-width: 72px;
  border-radius: 50%;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}



//Custom slick arrow
//.slick-arrow{
//  width: auto;
//  height: auto;
//  &.slick-prev::before,
//  &.slick-next::before {
//    font-family: inherit;
//    font-size: 36px;
//    line-height: 1;
//    opacity: 1;
//    color: $gray;
//  }
//  &.slick-prev {
//    left: -30px;
//  }
//  &.slick-next {
//    right: -30px;
//  }
//}

.slick-arrow{
  &.slick-prev::before,
  &.slick-next::before {
    font-family: inherit;
    font-size: 24px;
    line-height: 1;
    opacity: 1;
    color: $gray;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 2px 15px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.slick-prev {
    left: -60px;
    @media (max-width:767px) {
      left: 0;
      width: 30px;
      height: 30px;
      z-index: 9;
    }

  }
  &.slick-next {
    right: -30px;
    @media (max-width:767px) {
      right: 10px;
      width: 30px;
      height: 30px;
      z-index: 9;
   
    }

  }
  &.slick-arrow{
    &.slick-disabled{
      display: none !important;
    }
  }
  &.slick-prev::before {
    font-family: "Material Icons";
    content: '\e5cb';
  }
  &.slick-next::before {
    font-family: "Material Icons";
    content: '\e5cc';
  }
}

.more-feature-slider{
  .slick-arrow{
    &.slick-prev {
      left: 60px;
      z-index: 99;
    }
    &.slick-next {
      right: 60px;
      z-index: 99;
    }
  }
}




//Table
.table{
  box-shadow: 0 0 3px rgba(0,0,0,0.1);
  background-color: $white;
  > :not(:first-child) {
    border-top: 2px solid #6f6f6f;
  }
  > thead {
    tr{
      th{
        background-color: #6f6f6f;
        color: #fff;
      }
    }
  }
  > tbody,
  > thead{
    tr{
      td,
      th{
        font-size: 14px;
        font-weight: 400;
        padding: 1rem 1.8rem;
        white-space: nowrap;
      }
      td{
        &:nth-child(2){
          color:#736144;
          white-space: normal;
        }
      }
    }
  }

  &.table-hover > tbody > tr:hover > * {
    background-color: #f1f1f1
  }
}
