.how-affiliate-work {
  padding: 100px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .affiliate-card {
    margin: 30px 0 0 0;
    padding: 30px;
    transition: all 0.5s;

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.13);
      .icon {
        transform: scale(0.9);
      }
    }

    @media (max-width: 768px) {
      margin: 10px 0;
    }

    .icon {
      width: 100px;
      height: 100px;
      color: #F05A5A;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      transition: all 0.5s;
    }
    p{
      letter-spacing: .5px;
      word-spacing: 3px;
    }
    h3 {
      font-size: 19px;
      font-weight: 500;
      color: $black;
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }
}
