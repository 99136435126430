.domain-search{
    min-height: 366px;
    max-height: 366px;
    background-image: url("../../images/domain_bg.jpg");
    background-size: cover;
    position: relative;
    padding: 90px 0 0 0;


    @media (max-width:768px ) {
      max-height: 100%;
      padding: 50px 15px;
    }

    &:after{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: url("../../images/cloud_img.png");
      width: 100%;
      height: 75px;
      z-index: 1;
      @media (max-width:767px) {
        display: none;
      }
    }

    .search_domain_box {
      background-color: #fff;
      border-radius: 4px;
      padding: 60px 100px 0;
      position: relative;
      z-index: 9;
      text-align: center;
      @media (max-width: 991px) {
        padding: 60px 5px 0;
      }
      @media (max-width:768px ) {
        margin-bottom: -30px;
        padding: 40px 15px;
      }

      h2 {
        font-size: 48px;
        line-height: 48px;
        font-weight: 700;
        font-family: $font-ubuntu;
        color: $black;
      }
      p{
        font-size: 20px;
        line-height: 24px;
        color: $gray;
        font-weight: 300;
      }
      .search_domain_field {
        width: 100%;
        border: 1px solid #cfd3da;
        border-radius: 4px;
        font-size: 18px;
        line-height: 18px;
        color: $dark;
        font-weight: 400;
        padding: 25px;
        box-shadow: 0 0 30px 0 rgba(0,0,0,.10);
        &:focus{
          border-color: $dark;
        }
        &::-webkit-input-placeholder { /* Edge */
          color: #9296a7;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #9296a7;
        }

        &::placeholder {
          color: #9296a7;
        }
        @media (max-width: 991px) {
          padding: 15px;
        }
        @media (max-width:768px ) {
          font-size: 14px;
          padding: 10px 15px;
        }
      }
      @media (max-width:768px ) {
        h2{
          font-size: 28px;
          line-height: 28px;
        }
        p{
          font-size: 16px;
        }
      }
      .domain-search-form{
        position: relative;
        margin-top: 35px;
        .lvh-btn{
          position: absolute;
          right: 5px;
          top: 5px;
          padding: 21px 60px;

          &:hover{
            background-color: $red;
            color: $white;
          }
          @media (max-width: 991px) {
            padding: 12px 60px;
          }
          @media (max-width:768px ) {
            position: static;
            padding: 12px 25px;
            width: 100%;
            margin-top: 16px;
          }
        }
      }
    }


}
