// Fonts size
.font-12{
    font-size: 12px;
}
.font-13{
    font-size: 13px;
}
.font-14{
    font-size: 14px;
}
.font-15{
    font-size: 15px;
}
.font-16{
    font-size: 16px;
}
.font-18{
    font-size: 18px;
}
.font-20{
    font-size: 20px;
}
.font-22{
    font-size: 22px;
}
.font-24{
    font-size: 24px;
}
.font-26{
    font-size: 26px;
}
.font-28{
    font-size: 28px;
}
.font-30{
    font-size: 30px;
}
.font-32{
    font-size: 32px;
}
.font-34{
    font-size: 34px;
}
.font-36{
    font-size: 36px;
}
.font-38{
    font-size: 38px;
}
.font-30{
    font-size: 30px
}
.font-42{
    font-size: 42px;
}


//Text Color
.text-blue{
    color: $blue-dark !important;
}
.text-yellow{
    color: $yellow !important;
}

// Spaceing
.mt{
    &-10{
        margin-top: 10px;
    }
    &-20{
        margin-top: 20px;
    }
    &-30{
        margin-top: 30px;
    }
    &-40{
        margin-top: 40px;
    }
    &-50{
        margin-top: 50px;
    }
}
.pt{
    &-10{
       padding-top: 10px;
    }
    &-20{
        padding-top: 20px;
    }
    &-30{
        padding-top: 30px;
    }
    &-40{
        padding-top: 40px;
    }
    &-50{
        padding-top: 50px;
    }
}



// Color Classes
.bg-red{
    background-color: $color-primary !important;
}
.bg-purple{
    background-color: $purple !important;
}
.bg-yellow{
    background-color: $yellow !important
}
.bg-red-dark{
    background-color: $red-dark !important;
}
.bg-green{
    background-color: $green !important;
}
.bg-blue{
    background-color: $blue !important;
}
.bg-gray{
    background-color: $light-gray !important;
}
.bg-dark-blue{
    background-color: $blue-dark !important;
}

//Height
.height-p-100{
    height: 100%;
}

//blank-space
.blank-space{
    display: block;
    height: 40px;
    width: 100%;
}
.bg-common-space{
    padding: 80px 0;
    @media (max-width: 768px) {
        padding: 40px 0;
    }
}

//Hover letter Space
.hover-letter{
    letter-spacing: 0;
    transition: letter-spacing 0.2s ease-in;
    &:hover{
        letter-spacing: 1px !important;
    }
}
