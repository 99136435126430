.heading-big{
  h3{
    max-width: 60%;
    margin: 0 auto;
    font-size: 60px;
    font-weight: 700;
    color: $black;
    font-family: $font-ubuntu;

    @media (max-width: 767px) {
      font-size: 24px;
      max-width: 100%;
    }
  }
}
