.section-head-two{
    span{
      font-weight: 600;
      text-transform: uppercase;
      font-size: 16px;
      display: block;
    }
    h3{
      font-size: 32px;
      font-weight: 700;
      color: $blue-dark;
      max-width: 30%;
      margin: 10px auto 30px auto;

      @media (max-width: 767px) {
        max-width: 100%;
        font-size: 24px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 40%;
      }
    }
}
