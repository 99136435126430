.vps-plans-feature {
  background-color: #eff6f8;
  padding-top: 100px;
  overflow: hidden;
  .vps-plan-inner {
    border: 1px solid #d5d1ef;
    padding: 40px;
    background-color: #fff;
  }
  .vps-box {
    border: 1px solid #d5d1ef;
    height: 230px;
    text-align: center;
    margin-bottom: 30px;

    .vps-item-name {
      height: 150px;
      border-bottom: 1px solid #d5d1ef;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h3 {
        font-size: 18px;
        color: #230fae;
        font-weight: 500;
        margin-top: 15px;
      }
    }
    .vps-item-value {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      h3 {
        display: block;
        color: #000;
        font-weight: 500;
        font-size: 18px;
      }
    }

    &.vps-box-blue {
      background-color: #230fae;
      border-color: #230fae;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        font-size: 17px;
        line-height: 24px;
        color: #9f1ee5;
        font-weight: 500;
      }
      h3 {
        line-height: 24px;
        font-size: 30px;
        font-weight: 500;
        color: #fff;
      }
      .btn {
        color: #222;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        background: #fff;
        text-decoration: none;
        font-weight: 500;
        padding: 10px 30px;
        border: 2px solid #fff;
        border-radius: 5px;
        margin-top: 30px;
        transition: all ease-in-out 0.4s;
        &:hover {
          background-color: #9f1ee5;
          color: #fff;
          border-color: #9f1ee5;
        }
      }
      @media (min-width: 768px) and (max-width: 991px) {
        padding: 0 10px;
        h3 {
          font-size: 19px;
        }
        .btn {
          padding: 10px 20px;
        }
      }
    }
  }
}

.hosting_feature_section {
  padding: 120px 0;
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    background: url("../../images/plan-bg2.svg") no-repeat top right;
    background-size: cover;
    right: -56%;
    width: 100%;
    height: 1000px;
    opacity: 0.15;
    top: -110px;
    position: absolute;
    z-index: 1;
    @media (max-width: 1024px) {
      width: 150%;
    }
    @media (max-width: 686px) {
      width: 156%;
    }
  }
  .section-heading {
    margin-bottom: 60px;
  }
  .vps_hosting_feature_box {
    background-color: #fff;
    border: 1px solid #d5d1ef;
    padding: 0 15px;
    transition: all ease-in-out 0.5s;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    z-index: 9;

    .vps_feature_content {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      padding: 10px 0 0 20px;
    }
  }
}

// price-slider-section
.price-slider-section {
  padding-top: 50px;
  height: 90%;
  padding-left: 50px;
  .rangeslider-vertical {
    margin: 0;
    height: 100%;
    max-width: 30px;
    background-color: transparent;
  }
  .rangeslider {
    box-shadow: none;
    border: 1px solid #ddd;
  }
  .rangeslider-vertical .rangeslider__fill {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#9f1ee5+0,230fae+100 */
    background: rgb(159, 30, 229); /* Old browsers */
    background: -moz-linear-gradient(
      top,
      rgba(159, 30, 229, 1) 0%,
      rgba(35, 15, 174, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(159, 30, 229, 1) 0%,
      rgba(35, 15, 174, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(159, 30, 229, 1) 0%,
      rgba(35, 15, 174, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9f1ee5', endColorstr='#230fae',GradientType=0 ); /* IE6-9 */

    border: 0;
  }
  .rangeslider-vertical .rangeslider__handle {
    width: 60px;
    height: 40px;
    left: -16px;
    border: 3px solid #fff;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#9f1ee5+0,230fae+100 */
    background: rgb(159, 30, 229); /* Old browsers */
    background: -moz-linear-gradient(
      top,
      rgba(159, 30, 229, 1) 0%,
      rgba(35, 15, 174, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(159, 30, 229, 1) 0%,
      rgba(35, 15, 174, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(159, 30, 229, 1) 0%,
      rgba(35, 15, 174, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9f1ee5', endColorstr='#230fae',GradientType=0 ); /* IE6-9 */
    @media (max-width: 575px) {
      width: 50px;
      height: 33px;
      top: 1060px;
    }
  }
  .rangeslider-vertical .rangeslider__labels {
    margin-top: 20px;
    left: 52px;
    display: block;
    text-transform: uppercase;
    color: #555;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    @media (max-width: 575px) {
        left: 15px;
    }
    .rangeslider__label-item {
      &::before {
        display: none;
      }
    }
  }
  @media (max-width: 575px) {
    padding-left: 0px;
    .rangeslider-vertical {
      max-width: 20px;
    }
  }
}
