.hosting-plan-slider {
  padding: 70px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  &.business-plan-slider {
    .outer_hosting_plan_card {
      .hosting_plan_card {
        .hosting_plan_list {
          min-height: 525px;
          @media (min-width: 1400px) {
            min-height: 425px;
          }
        }
      }
    }
  }
  &.shared-hosting-plan-slider {
    .outer_hosting_plan_card {
      .hosting_plan_card {
        .heading_hosting_plan {
          p {
            min-height: 90px;
          }
        }
      }
    }
  }
  .outer_hosting_plan_card {
    .hosting_plan_card {
      padding: 40px 45px 25px;
      background-color: #fff;
      border-left: 5px solid transparent;
      transition: all ease-in-out 0.5s;
      position: relative;
      overflow: hidden;
      .heading_hosting_plan {
        .green_clr {
          color: #ff4800;
        }
      }
      .hosting_plan_list {
        text-align: left;
        padding-top: 20px;
        li {
          font-size: 16px;
          line-height: 36px;
          font-weight: 400;
          color: #222;
          position: relative;
          padding-left: 30px;
          &:before {
            position: absolute;
            content: "";
            background: url(../../images/hosting-plan/check_mark.png);
            width: 20px;
            height: 20px;
            left: 0;
            top: 8px;
          }
        }
      }
      .recommended {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        background-color: #ff4800;
        height: 30px;
        width: 115px;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: #fff;
        font-size: 9px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 5px 5px 0 0;
        letter-spacing: 2px;
      }
    }
    .bg_img01:before {
      position: absolute;
      content: "";
      background: url(../../images/hosting-plan/hosting_plan_img01.png);
      width: 225px;
      height: 196px;
      right: -105px;
      bottom: -15px;
      transition: all ease-in-out 0.5s;
    }
    .bg_img02:before {
      position: absolute;
      content: "";
      background: url(../../images/hosting-plan/hosting_plan_img02.png);
      width: 225px;
      height: 196px;
      right: -105px;
      bottom: -15px;
      transition: all ease-in-out 0.5s;
    }
    .bg_img03:before {
      position: absolute;
      content: "";
      background: url(../../images/hosting-plan/hosting_plan_img03.png);
      width: 225px;
      height: 196px;
      right: -105px;
      bottom: -15px;
      transition: all ease-in-out 0.5s;
    }
    .hosting_plan_price_box {
      padding: 30px 0 40px;
      background-color: #fbfbfb;
      .price_box {
        font-size: 60px;
        font-weight: 300;
        line-height: 72px;
        position: relative;
        color: #222;
        span {
          font-size: 42px;
          position: relative;
          top: -23px;
        }
        small {
          font-size: 18px;
        }
      }
      .hosting_plan_card_btn {
        padding: 14px 40px;
        color: #fff;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        padding: 20px 30px;
        text-transform: uppercase;
        transition: all ease-in-out 0.5s;
        background-color: #666;
        border: 2px solid #666;
        display: block;
        margin: 0 auto;
        max-width: 200px;
      }
      .off-price {
        margin-bottom: 10px;
        display: inline-block;
      }
    }
    &:hover {
      .hosting_plan_card {
        border-left: 5px solid #ff4800;
      }
      .hosting_plan_price_box {
        .hosting_plan_card_btn {
          background-color: #ff4800;
          border: 2px solid #ff4800;
        }
      }
      .bg_img01:before,
      .bg_img02:before,
      .bg_img03:before {
        transform: scale(1.1);
      }
    }
  }

  .hosting-plan-slider-inner {
    background: #ffffffb0;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.13);
    border-left: 5px solid transparent;
    transition: all 0.5s;
    &:hover {
      border-left: 5px solid #ff4800;
      .bg-container {
        transform: scale(1.1);
      }
      .btn-order {
        background: #ff4800;
        border: 1px solid #ff4800;
      }
    }
    .green_clr {
      color: #ff4800;
    }
    .bg-container {
      background-repeat: no-repeat;
      background-position: center right;
      background-size: contain;
      width: 190px;
      height: 190px;
      position: absolute;
      top: calc(50% - 100px);
      right: -70px;
      transition: all 0.5s;
    }
    .bg-container-first {
      background: url("../../images/hosting-plan/hosting_plan_img01.png");
    }
    .bg-container-second {
      background: url("../../images/hosting-plan/hosting_plan_img02.png");
    }
    .bg-container-third {
      background: url("../../images/hosting-plan/hosting_plan_img03.png");
    }
    .hosting-plan-wrap {
      padding: 0 1rem;
      margin: 2rem 0.5rem;
    }
    //hosting-plan
    .hosting-plan {
      // background: #fff;
      padding: 2.25rem;
      padding-top: 2.25rem;
      position: relative;
      z-index: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      position: relative;
      .off-price {
        margin-bottom: 30px;
      }
      .recommended {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        background-color: #ff4800;
        height: 30px;
        width: 115px;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: #fff;
        font-size: 9px;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 5px 5px 0 0;
        letter-spacing: 2px;
      }
      header {
        text-align: center;
      }
      .heading {
        font-size: 27px;
        font-weight: 600;
        color: #57526f;
      }
      .price {
        font-size: 29px;
        font-weight: normal;
        font-family: "Ubuntu", sans-serif;
        color: #0a4040;
        margin-top: 35px;
        margin-bottom: 5px;
        .amount {
          font-size: 50px;
        }
        small {
          font-size: 18px;
        }
      }
      p {
        font-size: 0.875rem;
        color: $black;
        font-weight: 400;
        margin-top: 30px;
        text-align: left;
      }

      .plan-feature {
        min-height: 240px;
        ul {
          li {
            display: flex;
            justify-content: flex-start;
            position: relative;
            margin-bottom: 15px;
            color: $gray;
            .done-icon {
              width: 20px;
              height: 20px;
              margin-right: 15px;
              background: #ff4800;
              color: white;
              font-weight: bold;
              font-size: 60px;
              padding: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
            p {
              margin: 0;
            }
            .advanced-text {
              display: none;
              color: #8b8c90;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.2;
              list-style-type: none;
              padding-bottom: 0;
              margin-bottom: 0;
              letter-spacing: 0;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

// hosting-plan-slider
.hosting-plan-slider {
  .hosting-plan-card {
    margin-bottom: 30px;
    border-radius: 7px;
    background: #fff;
    border: solid 1px #dde4eb;
    text-align: center;
    position: relative;
    overflow: hidden;
    &:hover {
      box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
        0 4px 16px 0 rgba(167, 175, 183, 0.33);
    }
    .plan-top {
      padding: 15px;
      .logo {
        min-height: 32px;
        width: auto;
        img {
          max-height: 25px;
          width: auto;
        }
      }
      a {
        font-size: 15px;
        font-weight: 500;
        min-height: 36px;
        margin-bottom: 7px;
        line-height: 1.2;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: #000;
      }
      h3 {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        line-height: 1;
        .rrp_price {
          font-size: 18px;
          font-weight: 500;
          color: #626365;
          text-decoration: line-through;
          padding-right: 10px;
        }
        .main_price {
          font-size: 30px;
          font-weight: 600;
          color: #000;
        }
      }
      .btn {
        border: 0;
        padding: 2px 15px 0;
        line-height: 27px;
        font-size: 14px;
        background-color: #ee4343;
        color: #fff;
        box-shadow: 0 3px 0 0 #ca4e19;
        &:hover {
          background-color: #faa51e;
          box-shadow: 0 3px 0 0 #c88110;
        }
      }
    }
    .plan-bottom {
      background-color: #f3f3f3;
      padding: 15px;
      p {
        margin: 2px 0;
        font-size: 13px;
      }
    }
  }
}
